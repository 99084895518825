import React, { useState } from "react";
import CasesCards from "components/CasesCards";
import mock from "./Cases.mock";
import * as S from "./Cases.styles";
import { useIntl } from "gatsby-plugin-intl";
import useWindowDimensions from "hooks/useWindowDimensions";

const Cases = () => {
  const [active, setActive] = useState(0);
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();

  const isMobile = width < 768;

  return (
    <S.Container id="cases">
      <S.Content>
        <S.Title>Cases</S.Title>
        <S.Nav>
          <S.NavUl>
            {[...Array(6)].map((_, index) => (
              <S.NavLink
                active={index === active}
                onClick={() => setActive(index)}
                key={index}
              >
                <p>
                  {formatMessage({ id: `cases.title-${index + 1}` })}
                  <div
                    style={{
                      width: "100%",
                      borderBottomStyle:
                        index === active && !isMobile ? "solid" : "unset",
                      borderBottomColor:
                        index === active && !isMobile
                          ? "rgb(46, 229, 119)"
                          : "transparent",
                      borderWidth:
                        index === active && !isMobile ? "4px" : "0px",
                      borderRadius:
                        index === active && !isMobile ? "10px" : "0px",
                    }}
                  />
                </p>
              </S.NavLink>
            ))}
          </S.NavUl>
        </S.Nav>
        <CasesCards data={mock[active] || []} active={active} />
      </S.Content>
    </S.Container>
  );
};

export default Cases;
