import netflixLogo from "assets/images/cards/netflix-logo.png";

import netflixBg4 from "assets/images/cards/netflix4-bg.png";
import chilliBeansLogo from "assets/images/cards/chilli_beans_Logo.png";
import chilliBeansBg from "assets/images/cards/chilli-beans-bg.png";
import cocacolaLogo from "assets/images/cards/coca-cola-logo.png";
import cocacolaBg from "assets/images/cards/coca-cola-bg.png";

import netflixTudum1 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-1.jpg";
import netflixTudum2 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-2.jpg";
import netflixTudum3 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-3.jpg";
import netflixTudum4 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-4.jpg";
import netflixTudum5 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-5.jpg";
import netflixTudum6 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-6.jpg";
import netflixTudunBackground from "assets/images/modalSlider/netflix-tudum/netflix-tudum-7.jpg";
import netflixCCXP1 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-1.jpg";
import netflixCCXP2 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-2.jpg";
import netflixCCXP3 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-3.jpg";
import netflixCCXP4 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-4.jpg";
import netflixCCXP5 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-5.jpg";
import netflixCCXP6 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-6.jpg";
import netflixCCXP7 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-7.jpg";

import chilliBeans1 from "assets/images/modalSlider/chilli-beans/chilli-beans-1.jpg";
import chilliBeans2 from "assets/images/modalSlider/chilli-beans/chilli-beans-2.jpg";
import chilliBeans3 from "assets/images/modalSlider/chilli-beans/chilli-beans-3.jpg";
import chilliBeans4 from "assets/images/modalSlider/chilli-beans/chilli-beans-4.jpg";
import chilliBeans5 from "assets/images/modalSlider/chilli-beans/chilli-beans-5.jpg";
import fanta1 from "assets/images/modalSlider/fanta/fanta-1.jpg";
import fanta2 from "assets/images/modalSlider/fanta/fanta-2.jpg";
import fanta3 from "assets/images/modalSlider/fanta/fanta-3.jpg";
import fanta4 from "assets/images/modalSlider/fanta/fanta-4.jpg";
import fanta5 from "assets/images/modalSlider/fanta/fanta-5.jpg";
import microsoftLogoTransparente from "assets/images/clientsSlider/microsoftTransparente.png";
import * as MCAPS from "assets/images/cards/MCAPS";
import * as CRIE from "assets/images/cards/CRIE";

import * as activision from "assets/images/cards/activision";
import * as olx from "assets/images/cards/olx";
import * as creatorWeek22 from "assets/images/cards/creatorWeek22";

// TIKTOK
import tiktok_criadores_logo from "assets/images/modalSlider/tiktok-criadores/tiktok-logo.png";
import tiktok_criadores_01 from "assets/images/modalSlider/tiktok-criadores/01.jpg";
import tiktok_criadores_02 from "assets/images/modalSlider/tiktok-criadores/02.jpg";
import tiktok_criadores_03 from "assets/images/modalSlider/tiktok-criadores/03.jpg";
import tiktok_criadores_04 from "assets/images/modalSlider/tiktok-criadores/04.jpg";
import tiktok_criadores_05 from "assets/images/modalSlider/tiktok-criadores/05.jpg";
import tiktok_criadores_06 from "assets/images/modalSlider/tiktok-criadores/06.jpg";
import tiktok_criadores_07 from "assets/images/modalSlider/tiktok-criadores/07.jpg";

// NBA
import nba_logo from "assets/images/modalSlider/nba/nba-logo.png";
import nba01 from "assets/images/modalSlider/nba/01.jpg";
import nba02 from "assets/images/modalSlider/nba/02.jpg";
import nba03 from "assets/images/modalSlider/nba/03.jpg";
import nba04 from "assets/images/modalSlider/nba/04.jpg";
import nba05 from "assets/images/modalSlider/nba/05.jpg";
import nba06 from "assets/images/modalSlider/nba/06.jpg";
import nba07 from "assets/images/modalSlider/nba/07.jpg";
import nba08 from "assets/images/modalSlider/nba/08.jpg";

// META CONVERSATIONS
import meta_logo from "assets/images/modalSlider/meta-conversations/meta-logo.png";
import meta01 from "assets/images/modalSlider/meta-conversations/01.jpg";
import meta02 from "assets/images/modalSlider/meta-conversations/02.jpg";
import meta03 from "assets/images/modalSlider/meta-conversations/03.jpg";
import meta04 from "assets/images/modalSlider/meta-conversations/04.jpg";

// HOUSE OF INSTAGRAM
import houseofinstagram01 from "assets/images/modalSlider/house-instagram/01.jpg";
import houseofinstagram02 from "assets/images/modalSlider/house-instagram/02.jpg";
import houseofinstagram03 from "assets/images/modalSlider/house-instagram/03.jpg";
import houseofinstagram04 from "assets/images/modalSlider/house-instagram/04.jpg";
import houseofinstagram05 from "assets/images/modalSlider/house-instagram/05.jpg";

// AVATAR
import avatarlogo from "assets/images/modalSlider/avatar-netflix/netflix-logo.png";
import avatar01 from "assets/images/modalSlider/avatar-netflix/01.jpg";
import avatar02 from "assets/images/modalSlider/avatar-netflix/02.jpg";
import avatar03 from "assets/images/modalSlider/avatar-netflix/03.jpg";
import avatar04 from "assets/images/modalSlider/avatar-netflix/04.jpg";

// XBOX FAN FEST
import xbox_fan_fest_logo from "assets/images/modalSlider/xbox-fan-fest/xbox-logo.png";
import xbox01 from "assets/images/modalSlider/xbox-fan-fest/01.jpg";
import xbox02 from "assets/images/modalSlider/xbox-fan-fest/02.jpg";
import xbox03 from "assets/images/modalSlider/xbox-fan-fest/03.jpg";
import xbox04 from "assets/images/modalSlider/xbox-fan-fest/04.jpg";

// NUBANK
import nubank_logo from "assets/images/modalSlider/nubank-monstro/nu_ultravioleta-logo.png";
import nubank01 from "assets/images/modalSlider/nubank-monstro/01.jpg";
import nubank02 from "assets/images/modalSlider/nubank-monstro/02.jpg";
import nubank03 from "assets/images/modalSlider/nubank-monstro/03.jpg";

// VERMELHO - amazon prime
import amazonprime_logo from "assets/images/modalSlider/vermelho/amazon_prime-logo.png";
import vermelho01 from "assets/images/modalSlider/vermelho/01.jpg";
import vermelho02 from "assets/images/modalSlider/vermelho/02.jpg";
import vermelho03 from "assets/images/modalSlider/vermelho/03.jpg";
import vermelho04 from "assets/images/modalSlider/vermelho/04.jpg";

// Vale VPS
import vale_vps_logo from "assets/images/modalSlider/vale-vps-2024/vale-logo.png";
import valevps2024_01 from "assets/images/modalSlider/vale-vps-2024/01.jpg";
import valevps2024_02 from "assets/images/modalSlider/vale-vps-2024/02.jpg";
import valevps2024_03 from "assets/images/modalSlider/vale-vps-2024/03.jpg";
import valevps2024_04 from "assets/images/modalSlider/vale-vps-2024/04.jpg";

// Microsoft Empower
import microsoft_empower_logo from "assets/images/modalSlider/microsoft-empower/microsoft-logo.png";
import microsoft_empower01 from "assets/images/modalSlider/microsoft-empower/01.png";
import microsoft_empower02 from "assets/images/modalSlider/microsoft-empower/02.png";
import microsoft_empower03 from "assets/images/modalSlider/microsoft-empower/03.png";
import microsoft_empower04 from "assets/images/modalSlider/microsoft-empower/04.png";

// Prime showcase
import primeshowcase_logo from "assets/images/modalSlider/prime-showcase/amazon_prime-logo.png";
import primeshowcase01 from "assets/images/modalSlider/prime-showcase/01.jpg";
import primeshowcase02 from "assets/images/modalSlider/prime-showcase/02.jpg";
import primeshowcase03 from "assets/images/modalSlider/prime-showcase/03.jpg";
import primeshowcase04 from "assets/images/modalSlider/prime-showcase/04.jpg";

// Localiza Lolla
import localizalolla_logo from "assets/images/modalSlider/localiza-lolla/localiza-logo.png";
import localizalolla01 from "assets/images/modalSlider/localiza-lolla/01.jpg";
import localizalolla02 from "assets/images/modalSlider/localiza-lolla/02.jpg";
import localizalolla03 from "assets/images/modalSlider/localiza-lolla/03.jpg";
import localizalolla04 from "assets/images/modalSlider/localiza-lolla/04.jpg";

// Globo Lolla
import globololla_logo from "assets/images/modalSlider/globo-lolla/globo-logo.png";
import globololla01 from "assets/images/modalSlider/globo-lolla/01.jpg";
import globololla02 from "assets/images/modalSlider/globo-lolla/02.jpg";
import globololla03 from "assets/images/modalSlider/globo-lolla/03.jpg";
import globololla04 from "assets/images/modalSlider/globo-lolla/04.jpg";

// tiktok vidcon
import tiktok_vidcon_logo from "assets/images/modalSlider/tiktok-vidcon/tiktok-logo.png";
import tiktok_vidcon01 from "assets/images/modalSlider/tiktok-vidcon/01.jpg";
import tiktok_vidcon02 from "assets/images/modalSlider/tiktok-vidcon/02.jpg";
import tiktok_vidcon03 from "assets/images/modalSlider/tiktok-vidcon/03.jpg";
import tiktok_vidcon04 from "assets/images/modalSlider/tiktok-vidcon/04.jpg";

// ccxp mexico
import ccxp_mexico_logo from "assets/images/modalSlider/ccxp-mexico/ccxp-logo.png";
import ccxp_mexico01 from "assets/images/modalSlider/ccxp-mexico/01.jpg";
import ccxp_mexico02 from "assets/images/modalSlider/ccxp-mexico/02.jpg";
import ccxp_mexico03 from "assets/images/modalSlider/ccxp-mexico/03.png";
import ccxp_mexico04 from "assets/images/modalSlider/ccxp-mexico/04.jpg";

// marykay
import marykay_logo from "assets/images/modalSlider/marykay/marykay-logo.png";
import marykay01 from "assets/images/modalSlider/marykay/01.jpg";
import marykay02 from "assets/images/modalSlider/marykay/02.jpg";
import marykay03 from "assets/images/modalSlider/marykay/03.jpg";

// gerdau the town
import gerdau_logo from "assets/images/modalSlider/gerdau/gerdau-logo.png";
import gerdau01 from "assets/images/modalSlider/gerdau/01.jpg";
import gerdau02 from "assets/images/modalSlider/gerdau/02.jpg";
import gerdau03 from "assets/images/modalSlider/gerdau/03.jpg";
import gerdau04 from "assets/images/modalSlider/gerdau/04.jpg";

// bayer talks
import bayer_logo from "assets/images/modalSlider/bayer-talks/bayer-logo.png";
import bayer01 from "assets/images/modalSlider/bayer-talks/01.jpg";
import bayer02 from "assets/images/modalSlider/bayer-talks/02.jpg";
import bayer03 from "assets/images/modalSlider/bayer-talks/03.jpg";
import bayer04 from "assets/images/modalSlider/bayer-talks/04.jpg";

// bayer acao
import bayeracao_logo from "assets/images/modalSlider/bayer-acao/bayer-logo.png";
import bayeracao01 from "assets/images/modalSlider/bayer-acao/01.jpg";
import bayeracao02 from "assets/images/modalSlider/bayer-acao/02.jpg";
import bayeracao03 from "assets/images/modalSlider/bayer-acao/03.jpg";
import bayeracao04 from "assets/images/modalSlider/bayer-acao/04.jpg";

// localiza convencao
import localizaconvencao_logo from "assets/images/modalSlider/localiza-convencao/localiza-logo.png";
import localizaconvencao01 from "assets/images/modalSlider/localiza-convencao/01.jpg";
import localizaconvencao02 from "assets/images/modalSlider/localiza-convencao/02.jpg";
import localizaconvencao03 from "assets/images/modalSlider/localiza-convencao/03.jpg";
import localizaconvencao04 from "assets/images/modalSlider/localiza-convencao/04.jpg";

// ccxp amazon prime
import amazonprimeccxp_logo from "assets/images/modalSlider/ccxp-amazon-2023/amazon_prime-logo.png";
import amazonprimeccxp01 from "assets/images/modalSlider/ccxp-amazon-2023/01.jpg";
import amazonprimeccxp02 from "assets/images/modalSlider/ccxp-amazon-2023/02.jpg";
import amazonprimeccxp03 from "assets/images/modalSlider/ccxp-amazon-2023/03.jpg";
import amazonprimeccxp04 from "assets/images/modalSlider/ccxp-amazon-2023/04.jpg";

// ccxp tres coracoes
import trescoracoesccxp_logo from "assets/images/modalSlider/ccxp-tres-coracoes-2023/tres-logo.png";
import trescoracoesccxp01 from "assets/images/modalSlider/ccxp-tres-coracoes-2023/01.jpg";
import trescoracoesccxp02 from "assets/images/modalSlider/ccxp-tres-coracoes-2023/02.jpg";
import trescoracoesccxp03 from "assets/images/modalSlider/ccxp-tres-coracoes-2023/03.jpg";
import trescoracoesccxp04 from "assets/images/modalSlider/ccxp-tres-coracoes-2023/04.jpg";
import trescoracoesccxp05 from "assets/images/modalSlider/ccxp-tres-coracoes-2023/05.jpg";

// ccxp netflix 2023
import netflixccxp2023_logo from "assets/images/modalSlider/ccxp-netflix-2023/netflix-logo.png";
import netflixccxp2023_01 from "assets/images/modalSlider/ccxp-netflix-2023/01.jpg";
import netflixccxp2023_02 from "assets/images/modalSlider/ccxp-netflix-2023/02.jpg";
import netflixccxp2023_03 from "assets/images/modalSlider/ccxp-netflix-2023/03.jpg";
import netflixccxp2023_04 from "assets/images/modalSlider/ccxp-netflix-2023/04.jpg";
import netflixccxp2023_05 from "assets/images/modalSlider/ccxp-netflix-2023/05.jpg";
import netflixccxp2023_06 from "assets/images/modalSlider/ccxp-netflix-2023/06.jpg";
import netflixccxp2023_07 from "assets/images/modalSlider/ccxp-netflix-2023/07.jpg";
import netflixccxp2023_08 from "assets/images/modalSlider/ccxp-netflix-2023/08.jpg";

// ccxp paramount 2023
import paramountccxp2023_logo from "assets/images/modalSlider/ccxp-paramount-2023/paramount-logo.png";
import paramountccxp2023_01 from "assets/images/modalSlider/ccxp-paramount-2023/01.jpg";
import paramountccxp2023_02 from "assets/images/modalSlider/ccxp-paramount-2023/02.jpg";
import paramountccxp2023_03 from "assets/images/modalSlider/ccxp-paramount-2023/03.jpg";
import paramountccxp2023_04 from "assets/images/modalSlider/ccxp-paramount-2023/04.jpg";
import paramountccxp2023_05 from "assets/images/modalSlider/ccxp-paramount-2023/05.jpg";
import paramountccxp2023_06 from "assets/images/modalSlider/ccxp-paramount-2023/06.jpg";
import paramountccxp2023_07 from "assets/images/modalSlider/ccxp-paramount-2023/07.jpg";
import paramountccxp2023_08 from "assets/images/modalSlider/ccxp-paramount-2023/08.jpg";

// ccxp xbox 2023
import xboxccxp2023_logo from "assets/images/modalSlider/ccxp-xbox-2023/xbox-logo.png";
import xboxccxp2023_01 from "assets/images/modalSlider/ccxp-xbox-2023/01.jpg";
import xboxccxp2023_02 from "assets/images/modalSlider/ccxp-xbox-2023/02.jpg";
import xboxccxp2023_03 from "assets/images/modalSlider/ccxp-xbox-2023/03.jpg";
import xboxccxp2023_04 from "assets/images/modalSlider/ccxp-xbox-2023/04.jpg";
import xboxccxp2023_05 from "assets/images/modalSlider/ccxp-xbox-2023/05.jpg";
import xboxccxp2023_06 from "assets/images/modalSlider/ccxp-xbox-2023/06.jpg";
import xboxccxp2023_07 from "assets/images/modalSlider/ccxp-xbox-2023/07.jpg";

// ccxp globo 2023
import logo_globo from "assets/images/modalSlider/ccxp-globo-2024/logo.png";

import globoccxp2023_logo from "assets/images/modalSlider/ccxp-globo-2023/globo-logo.png";
import globoccxp2023_01 from "assets/images/modalSlider/ccxp-globo-2023/01.jpg";
import globoccxp2023_02 from "assets/images/modalSlider/ccxp-globo-2023/02.jpg";
import globoccxp2023_03 from "assets/images/modalSlider/ccxp-globo-2023/03.jpg";
import globoccxp2023_04 from "assets/images/modalSlider/ccxp-globo-2023/04.jpg";
import globoccxp2023_05 from "assets/images/modalSlider/ccxp-globo-2023/05.jpg";
import globoccxp2023_06 from "assets/images/modalSlider/ccxp-globo-2023/06.jpg";
import globoccxp2023_07 from "assets/images/modalSlider/ccxp-globo-2023/07.jpg";

// ccxp outback 2023
import outbackccxp2023_logo from "assets/images/modalSlider/ccxp-outback-2023/outback-logo.png";
import outbackccxp2023_01 from "assets/images/modalSlider/ccxp-outback-2023/01.jpg";
import outbackccxp2023_02 from "assets/images/modalSlider/ccxp-outback-2023/02.jpg";
import outbackccxp2023_03 from "assets/images/modalSlider/ccxp-outback-2023/03.jpg";
import outbackccxp2023_04 from "assets/images/modalSlider/ccxp-outback-2023/04.jpg";
import outbackccxp2023_05 from "assets/images/modalSlider/ccxp-outback-2023/05.jpg";
import outbackccxp2023_06 from "assets/images/modalSlider/ccxp-outback-2023/06.jpg";

// RIOT na CCXP
import riotccxp2024_logo from "assets/images/modalSlider/riot-ccxp-2024/logo.png";
import riotccxp2024_01 from "assets/images/modalSlider/riot-ccxp-2024/1.jpg";
import riotccxp2024_02 from "assets/images/modalSlider/riot-ccxp-2024/2.jpg";
import riotccxp2024_03 from "assets/images/modalSlider/riot-ccxp-2024/3.jpg";
import riotccxp2024_04 from "assets/images/modalSlider/riot-ccxp-2024/4.jpg";

// MELI
import meli_logo from "assets/images/modalSlider/mercado-livre-experience/logo.png";
import meli_01 from "assets/images/modalSlider/mercado-livre-experience/1.jpg";
import meli_02 from "assets/images/modalSlider/mercado-livre-experience/2.jpg";
import meli_03 from "assets/images/modalSlider/mercado-livre-experience/3.jpg";
import meli_04 from "assets/images/modalSlider/mercado-livre-experience/4.jpg";
import meli_05 from "assets/images/modalSlider/mercado-livre-experience/5.jpg";

// D23
import d23_logo from "assets/images/modalSlider/d23/logo.png";
import d23_01 from "assets/images/modalSlider/d23/1.jpg";
import d23_02 from "assets/images/modalSlider/d23/2.jpg";
import d23_03 from "assets/images/modalSlider/d23/3.jpg";
import d23_04 from "assets/images/modalSlider/d23/4.jpg";

// Senna
import senna_logo from "assets/images/modalSlider/senna/logo.png";
import senna_01 from "assets/images/modalSlider/senna/1.jpg";
import senna_02 from "assets/images/modalSlider/senna/2.jpg";
import senna_03 from "assets/images/modalSlider/senna/3.jpg";
import senna_04 from "assets/images/modalSlider/senna/4.jpg";

// round6k
import round6k_logo from "assets/images/modalSlider/round6k/logo.png";
import round6k_01 from "assets/images/modalSlider/round6k/1.jpg";
import round6k_02 from "assets/images/modalSlider/round6k/2.jpg";
import round6k_03 from "assets/images/modalSlider/round6k/3.jpg";
import round6k_04 from "assets/images/modalSlider/round6k/4.jpg";

// globo na ccxp 2024
import globoccxp2024_logo from "assets/images/modalSlider/ccxp-globo-2024/globoplay.png";
import globoccxp2024_01 from "assets/images/modalSlider/ccxp-globo-2024/1.jpg";
import globoccxp2024_02 from "assets/images/modalSlider/ccxp-globo-2024/2.jpg";
import globoccxp2024_03 from "assets/images/modalSlider/ccxp-globo-2024/3.jpg";
import globoccxp2024_04 from "assets/images/modalSlider/ccxp-globo-2024/4.jpg";

// bis na ccxp 2024
import bisccxp2024_logo from "assets/images/modalSlider/ccxp-bis-2024/logo.png";
import bisccxp2024_01 from "assets/images/modalSlider/ccxp-bis-2024/1.jpg";
import bisccxp2024_02 from "assets/images/modalSlider/ccxp-bis-2024/2.jpg";
import bisccxp2024_03 from "assets/images/modalSlider/ccxp-bis-2024/3.jpg";
import bisccxp2024_04 from "assets/images/modalSlider/ccxp-bis-2024/4.jpg";

// amazon na ccxp 2024
import amazonccxp2024_logo from "assets/images/modalSlider/ccxp-amazon-2024/logo.png";
import amazonccxp2024_01 from "assets/images/modalSlider/ccxp-amazon-2024/1.jpg";
import amazonccxp2024_02 from "assets/images/modalSlider/ccxp-amazon-2024/2.jpg";
import amazonccxp2024_03 from "assets/images/modalSlider/ccxp-amazon-2024/3.jpg";
import amazonccxp2024_04 from "assets/images/modalSlider/ccxp-amazon-2024/4.jpg";

// xbox na ccxp 2024
import xboxccxp2024_logo from "assets/images/modalSlider/ccxp-xbox-2024/logo.png";
import xboxccxp2024_01 from "assets/images/modalSlider/ccxp-xbox-2024/1.jpg";
import xboxccxp2024_02 from "assets/images/modalSlider/ccxp-xbox-2024/2.jpg";
import xboxccxp2024_03 from "assets/images/modalSlider/ccxp-xbox-2024/3.jpg";

// paramount na ccxp
import paramountccxp2024_logo from "assets/images/modalSlider/ccxp-paramount-2024/logo.png";
import paramountccxp2024_01 from "assets/images/modalSlider/ccxp-paramount-2024/1.jpg";
import paramountccxp2024_02 from "assets/images/modalSlider/ccxp-paramount-2024/2.jpg";
import paramountccxp2024_03 from "assets/images/modalSlider/ccxp-paramount-2024/3.jpg";
import paramountccxp2024_04 from "assets/images/modalSlider/ccxp-paramount-2024/4.jpg";

const data = [
  // NBA
  {
    backgroundCard: nba04,
    logo: nba_logo,
    title: "NBA House",
    subtitle: "",
    type: "evento proprietário",
    data: [
      { video: "dK7UkEU4gkE" },
      {
        image: nba01,
        description: "NBA House",
      },
      {
        image: nba02,
        description: "NBA House",
      },
      {
        image: nba03,
        description: "NBA House",
      },
      {
        image: nba04,
        description: "NBA House",
      },
      {
        image: nba05,
        description: "NBA House",
      },
      {
        image: nba06,
        description: "NBA House",
      },
      {
        image: nba07,
        description: "NBA House",
      },
      {
        image: nba08,
        description: "NBA House",
      },
    ],
  },
  // MELI
  {
    backgroundCard: meli_01,
    logo: meli_logo,
    title: "Mercado Livre Experience",
    subtitle: "",
    type: "evento proprietário",
    data: [
      { video: "VLohJ6S2ttQ" },
      {
        image: meli_01,
        description: "Mercado Livre",
      },
      {
        image: meli_02,
        description: "Mercado Livre",
      },
      {
        image: meli_03,
        description: "Mercado Livre",
      },
      {
        image: meli_04,
        description: "Mercado Livre",
      },
      {
        image: meli_05,
        description: "Mercado Livre",
      },
    ],
  },
  {
    backgroundCard: riotccxp2024_01,
    logo: riotccxp2024_logo,
    title: "Arcane na CCXP",
    subtitle: "",
    type: "festivais",
    position: 6,
    data: [
      { video: "CdIq0ac1Fp4" },
      {
        image: riotccxp2024_01,
        description: "Arcane na CCXP",
      },
      {
        image: riotccxp2024_02,
        description: "Arcane na CCXP",
      },
      {
        image: riotccxp2024_03,
        description: "Arcane na CCXP",
      },
      {
        image: riotccxp2024_04,
        description: "Arcane na CCXP",
      },
    ],
  },
  {
    backgroundCard: senna_01,
    logo: senna_logo,
    title: "Senna",
    subtitle: "",
    type: "lançamentos",
    data: [
      { video: "dc-_tCS5llI" },
      {
        image: senna_01,
        description: "Senna",
      },
      {
        image: senna_02,
        description: "Senna",
      },
      {
        image: senna_03,
        description: "Senna",
      },
      {
        image: senna_04,
        description: "Senna",
      },
    ],
  },

  {
    backgroundCard: tiktok_vidcon02,
    logo: tiktok_vidcon_logo,
    title: "TikTok Vidcon",
    subtitle: "",
    type: "festivais",
    position: 7,
    data: [
      { video: "70nb97GQKa0" },
      {
        image: tiktok_vidcon01,
        description: "TikTok Vidcon",
      },
      {
        image: tiktok_vidcon02,
        description: "TikTok Vidcon",
      },
      {
        image: tiktok_vidcon03,
        description: "TikTok Vidcon",
      },
      {
        image: tiktok_vidcon04,
        description: "TikTok Vidcon",
      },
    ],
  },

  {
    backgroundCard: d23_01,
    logo: d23_logo,
    title: "D23",
    subtitle: "",
    type: "evento proprietário",
    data: [
      { video: "62HWe_ZWE_M" },
      {
        image: d23_01,
        description: "Disney - D23",
      },
      {
        image: d23_02,
        description: "Disney - D23",
      },
      {
        image: d23_03,
        description: "Disney - D23",
      },
      {
        image: d23_04,
        description: "Disney - D23",
      },
    ],
  },

  {
    backgroundCard: round6k_01,
    logo: round6k_logo,
    title: "Round 6k",
    subtitle: "",
    type: "lançamentos",
    data: [
      { video: "tSyikCAGq08" },
      {
        image: round6k_01,
        description: "Round 6k",
      },
      {
        image: round6k_02,
        description: "Round 6k",
      },
      {
        image: round6k_03,
        description: "Round 6k",
      },
      {
        image: round6k_04,
        description: "Round 6k",
      },
    ],
  },
  {
    backgroundCard: globoccxp2024_01,
    logo: globoccxp2024_logo,
    title: "Globoplay na CCXP 24",
    subtitle: "",
    type: "festivais",
    position: 9,
    data: [
      { video: "SpYshE9lErw" },
      {
        image: globoccxp2024_01,
        description: "Globoplay na CCXP 24",
      },
      {
        image: globoccxp2024_02,
        description: "Globoplay na CCXP 24",
      },
      {
        image: globoccxp2024_03,
        description: "Globoplay na CCXP 24",
      },
      {
        image: globoccxp2024_04,
        description: "Globoplay na CCXP 24",
      },
    ],
  },
  {
    backgroundCard: bisccxp2024_01,
    logo: bisccxp2024_logo,
    title: "Bis na CCXP 24",
    subtitle: "",
    type: "festivais",
    position: 1,
    data: [
      { video: "0DBIWesV6ec" },
      {
        image: bisccxp2024_01,
        description: "Bis na CCXP 24",
      },
      {
        image: bisccxp2024_02,
        description: "Bis na CCXP 24",
      },
      {
        image: bisccxp2024_03,
        description: "Bis na CCXP 24",
      },
      {
        image: bisccxp2024_04,
        description: "Bis na CCXP 24",
      },
    ],
  },
  {
    backgroundCard: amazonccxp2024_01,
    logo: amazonccxp2024_logo,
    title: "Amazon Prime na CCXP 24",
    subtitle: "",
    type: "festivais",
    position: 5,
    data: [
      {
        image: amazonccxp2024_01,
        description: "Amazon Prime na CCXP 24",
      },
      {
        image: amazonccxp2024_02,
        description: "Amazon Prime na CCXP 24",
      },
      {
        image: amazonccxp2024_03,
        description: "Amazon Prime na CCXP 24",
      },
      {
        image: amazonccxp2024_04,
        description: "Amazon Prime na CCXP 24",
      },
    ],
  },

  {
    backgroundCard: xboxccxp2024_01,
    logo: xboxccxp2024_logo,
    title: "Xbox na CCXP 24",
    subtitle: "",
    type: "festivais",
    position: 10,
    data: [
      { video: "EZk8umFaRH0" },
      {
        image: xboxccxp2024_01,
        description: "Xbox na CCXP 24",
      },
      {
        image: xboxccxp2024_02,
        description: "Xbox na CCXP 24",
      },
      {
        image: xboxccxp2024_03,
        description: "Xbox na CCXP 24",
      },
    ],
  },
  {
    backgroundCard: paramountccxp2024_01,
    logo: paramountccxp2024_logo,
    title: "Paramount na CCXP 24",
    subtitle: "",
    type: "festivais",
    position: 11,
    data: [
      { video: "bThikFdqUp0" },
      {
        image: paramountccxp2024_01,
        description: "Paramount na CCXP 24",
      },
      {
        image: paramountccxp2024_02,
        description: "Paramount na CCXP 24",
      },
      {
        image: paramountccxp2024_03,
        description: "Paramount na CCXP 24",
      },
      {
        image: paramountccxp2024_04,
        description: "Paramount na CCXP 24",
      },
    ],
  },
  // ok
  {
    backgroundCard: creatorWeek22.CreatorWeek22BG,
    logo: meta_logo,
    title: "Creator Week 22",
    subtitle: "",
    type: "evento proprietário",
    data: [
      {
        image: creatorWeek22.Image1,
        description: "Creator Week 22",
      },
      {
        image: creatorWeek22.Image2,
        description: "Creator Week 22",
      },
      {
        image: creatorWeek22.Image3,
        description: "Creator Week 22",
      },
      {
        image: creatorWeek22.Image4,
        description: "Creator Week 22",
      },
      {
        video: "g_b-SqDxXkE",
      },
    ],
  },
  // TIKTOK PARA CRIADORES
  {
    backgroundCard: tiktok_criadores_04,
    logo: tiktok_criadores_logo,
    title: "Tiktok para Criadores",
    type: "evento proprietário",
    subtitle: "",
    data: [
      {
        image: tiktok_criadores_01,
        description: "Tiktok para Criadores",
      },
      {
        image: tiktok_criadores_02,
        description: "Tiktok para Criadores",
      },
      {
        image: tiktok_criadores_03,
        description: "Tiktok para Criadores",
      },
      {
        image: tiktok_criadores_04,
        description: "Tiktok para Criadores",
      },
      {
        image: tiktok_criadores_05,
        description: "Tiktok para Criadores",
      },
      {
        image: tiktok_criadores_06,
        description: "Tiktok para Criadores",
      },
      {
        image: tiktok_criadores_07,
        description: "Tiktok para Criadores",
      },
    ],
  },

  // META CONVERSATIONS
  {
    backgroundCard: meta01,
    logo: meta_logo,
    title: "Meta Conversations",
    subtitle: "",
    type: "evento proprietário",
    data: [
      {
        image: meta01,
        description: "Meta Conversations",
      },
      {
        image: meta02,
        description: "Meta Conversations",
      },
      {
        image: meta03,
        description: "Meta Conversations",
      },
      {
        image: meta04,
        description: "Meta Conversations",
      },
    ],
  },
  // ok
  {
    backgroundCard: activision.ActivisionBG,
    logo: activision.Logo,
    title: "Call of Duty",
    subtitle: "",
    type: "lançamentos",
    data: [
      {
        image: activision.Image1,
        description: "Call of Duty",
      },
      {
        image: activision.Image2,
        description: "Call of Duty",
      },
      {
        image: activision.Image3,
        description: "Call of Duty",
      },
      {
        image: activision.Image4,
        description: "Call of Duty",
      },
      {
        video: "1KfgLSeLw8A",
      },
    ],
  },
  //  ok
  {
    backgroundCard: olx.ConectaOLXBG,
    logo: olx.Logo,
    title: "Conecta Imobi 22",
    type: "evento proprietário",
    subtitle: "",
    data: [
      {
        image: olx.Image1,
        description: "Conecta Imobi 22",
      },
      {
        image: olx.Image2,
        description: "Conecta Imobi 22",
      },
      {
        image: olx.Image3,
        description: "Conecta Imobi 22",
      },
      {
        image: olx.Image4,
        description: "Conecta Imobi 22",
      },
      {
        video: "VOSAB0VYf9Y",
      },
    ],
  },
  //  ok
  {
    backgroundCard: netflixBg4,
    logo: netflixLogo,
    title: "Netflix na CCXP 23",
    subtitle: "",
    type: "festivais",
    position: 12,
    data: [
      {
        image: netflixCCXP1,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixCCXP2,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixCCXP3,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixCCXP4,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixCCXP5,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixCCXP6,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixCCXP7,
        description: "Netflix na CCXP 23",
      },
      {
        video: "fMDjzj8puOc",
      },
    ],
  },
  // ok
  // {
  //   backgroundCard: chilliBeansBg,
  //   logo: chilliBeansLogo,
  //   title: "Chilli Beans - CCXP",
  //   subtitle: "",
  //   type: "festivais",
  //   data: [
  //     {
  //       image: chilliBeans1,
  //       description: "Chilli Beans - CCXP",
  //     },
  //     {
  //       image: chilliBeans2,
  //       description: "Chilli Beans - CCXP",
  //     },
  //     {
  //       image: chilliBeans3,
  //       description: "Chilli Beans - CCXP",
  //     },
  //     {
  //       image: chilliBeans4,
  //       description: "Chilli Beans - CCXP",
  //     },
  //     {
  //       image: chilliBeans5,
  //       description: "Chilli Beans - CCXP",
  //     },
  //     {
  //       video: "c9XSnZdXZC4",
  //     },
  //   ],
  // },
  // // ok
  // {
  //   backgroundCard: cocacolaBg,
  //   logo: cocacolaLogo,
  //   title: "Fanta - CCXP",
  //   subtitle: "",
  //   type: "festivais",
  //   data: [
  //     {
  //       image: fanta1,
  //       description: "Fanta - CCXP",
  //     },
  //     {
  //       image: fanta2,
  //       description: "Fanta - CCXP",
  //     },
  //     {
  //       image: fanta3,
  //       description: "Fanta - CCXP",
  //     },
  //     {
  //       image: fanta4,
  //       description: "Fanta - CCXP",
  //     },
  //     {
  //       image: fanta5,
  //       description: "Fanta - CCXP",
  //     },
  //     {
  //       video: "A-F4k4y_ZOA",
  //     },
  //   ],
  // },
  // TUDUM - Netflix
  {
    backgroundCard: netflixTudunBackground,
    logo: netflixLogo,
    title: "Tudum 2021",
    subtitle: "",
    type: "evento proprietário",
    data: [
      {
        image: netflixTudum1,
        description: "Tudum 2021",
      },
      {
        image: netflixTudum2,
        description: "Tudum 2021",
      },
      {
        image: netflixTudum3,
        description: "Tudum 2021",
      },
      {
        image: netflixTudum4,
        description: "Tudum 2021",
      },
      {
        image: netflixTudum5,
        description: "Tudum 2021",
      },
      {
        image: netflixTudum6,
        description: "Tudum 2021",
      },
      {
        video: "4OT_HcJHsr0",
      },
    ],
  },
  // House of instagram
  {
    backgroundCard: houseofinstagram01,
    logo: meta_logo,
    title: "House of Instagram (México)",
    subtitle: "",
    type: "evento proprietário",
    data: [
      {
        image: houseofinstagram01,
        description: "House of Instagram (México)",
      },
      {
        image: houseofinstagram02,
        description: "House of Instagram (México)",
      },
      {
        image: houseofinstagram03,
        description: "House of Instagram (México)",
      },
      {
        image: houseofinstagram04,
        description: "House of Instagram (México)",
      },
      {
        image: houseofinstagram05,
        description: "House of Instagram (México)",
      },
    ],
  },
  // netflix - avatar
  {
    backgroundCard: avatar03,
    logo: netflixLogo,
    title: "Avatar",
    subtitle: "",
    type: "lançamentos",
    data: [
      { video: "Ndsb2VAYwiY" },
      {
        image: avatar01,
        description: "Avatar",
      },
      {
        image: avatar02,
        description: "Avatar",
      },
      {
        image: avatar03,
        description: "Avatar",
      },
      {
        image: avatar04,
        description: "Avatar",
      },
    ],
  },
  // ok
  {
    backgroundCard: CRIE.CrieBG,
    logo: CRIE.logo,
    title: "Crie o Impossível 22",
    subtitle: "",
    type: "evento proprietário",
    data: [
      {
        image: CRIE.Image1,
        description: "Crie o Impossível 22",
      },
      {
        image: CRIE.Image2,
        description: "Crie o Impossível 22",
      },
      {
        image: CRIE.Image3,
        description: "Crie o Impossível 22",
      },
      {
        image: CRIE.Image4,
        description: "Crie o Impossível 22",
      },
      {
        image: CRIE.Image5,
        description: "Crie o Impossível 22",
      },
      {
        video: "aZC0KW8QLhA",
      },
    ],
  },
  // ok
  {
    backgroundCard: MCAPS.MicrosoftMcaps,
    logo: microsoftLogoTransparente,
    title: "MCAPS Start",
    subtitle: "",
    type: "corporativo & B2B",
    data: [
      {
        image: MCAPS.Image1,
        description: "MCAPS Start",
      },
      {
        image: MCAPS.Image2,
        description: "MCAPS Start",
      },
      {
        image: MCAPS.Image3,
        description: "MCAPS Start",
      },
      {
        image: MCAPS.Image4,
        description: "MCAPS Start",
      },
      {
        image: MCAPS.Image5,
        description: "MCAPS Start",
      },
    ],
  },
  {
    backgroundCard: xbox03,
    logo: xbox_fan_fest_logo,
    title: "Xbox Fan Fest",
    subtitle: "",
    type: "lançamentos",
    data: [
      { video: "sBzIESXcbVY" },
      {
        image: xbox01,
        description: "Xbox Fan Fest",
      },
      {
        image: xbox02,
        description: "Xbox Fan Fest",
      },
      {
        image: xbox03,
        description: "Xbox Fan Fest",
      },
      {
        image: xbox04,
        description: "Xbox Fan Fest",
      },
    ],
  },
  {
    backgroundCard: nubank02,
    logo: nubank_logo,
    title: "Não é um Monstro Experience",
    subtitle: "",
    type: "lançamentos",
    data: [
      {
        image: nubank01,
        description: "Não é um Monstro Experience",
      },
      {
        image: nubank02,
        description: "Não é um Monstro Experience",
      },
      {
        image: nubank03,
        description: "Não é um Monstro Experience",
      },
    ],
  },
  {
    backgroundCard: vermelho01,
    logo: amazonprime_logo,
    title: "Vermelho, Branco e Sangue Azul",
    subtitle: "",
    type: "lançamentos",
    data: [
      {
        image: vermelho01,
        description: "Vermelho, Branco e Sangue Azul",
      },
      {
        image: vermelho02,
        description: "Vermelho, Branco e Sangue Azul",
      },
      {
        image: vermelho03,
        description: "Vermelho, Branco e Sangue Azul",
      },
      {
        image: vermelho04,
        description: "Vermelho, Branco e Sangue Azul",
      },
    ],
  },
  {
    backgroundCard: valevps2024_01,
    logo: vale_vps_logo,
    title: "Vale VPS",
    subtitle: "",
    type: "corporativo & B2B",
    data: [
      { video: "9tRa8BGYHXs" },
      {
        image: valevps2024_01,
        description: "Vale VPS",
      },
      {
        image: valevps2024_02,
        description: "Vale VPS",
      },
      {
        image: valevps2024_03,
        description: "Vale VPS",
      },
      {
        image: valevps2024_04,
        description: "Vale VPS",
      },
    ],
  },
  {
    backgroundCard: microsoft_empower01,
    logo: microsoft_empower_logo,
    title: "Microsoft Empower",
    subtitle: "",
    type: "corporativo & B2B",
    data: [
      { video: "hv7gNyiYna4" },
      {
        image: microsoft_empower01,
        description: "Microsoft Empower",
      },
      {
        image: microsoft_empower02,
        description: "Microsoft Empower",
      },
      {
        image: microsoft_empower03,
        description: "Microsoft Empower",
      },
      {
        image: microsoft_empower04,
        description: "Microsoft Empower",
      },
    ],
  },
  {
    backgroundCard: primeshowcase03,
    logo: primeshowcase_logo,
    title: "Showcase",
    subtitle: "",
    type: "corporativo & B2B",
    data: [
      { video: "VLCJ07BUaLU" },
      {
        image: primeshowcase01,
        description: "Showcase",
      },
      {
        image: primeshowcase02,
        description: "Showcase",
      },
      {
        image: primeshowcase03,
        description: "Showcase",
      },
      {
        image: primeshowcase04,
        description: "Showcase",
      },
    ],
  },
  {
    backgroundCard: localizalolla01,
    logo: localizalolla_logo,
    title: "Localiza no Lolla 24",
    subtitle: "",
    type: "festivais",
    position: 4,
    data: [
      { video: "7bijwSwCJK4" },
      {
        image: localizalolla01,
        description: "Localiza no Lolla 24",
      },

      {
        image: localizalolla02,
        description: "Localiza no Lolla 24",
      },
      {
        image: localizalolla03,
        description: "Localiza no Lolla 24",
      },
      {
        image: localizalolla04,
        description: "Localiza no Lolla 24",
      },
    ],
  },
  {
    backgroundCard: globololla01,
    logo: logo_globo,
    title: "Globo no Lolla 24",
    subtitle: "",
    type: "festivais",
    position: 2,
    data: [
      { video: "V02w2lAubB0" },
      {
        image: globololla01,
        description: "Globo no Lolla 24",
      },
      {
        image: globololla02,
        description: "Globo no Lolla 24",
      },
      {
        image: globololla03,
        description: "Globo no Lolla 24",
      },
      {
        image: globololla04,
        description: "Globo no Lolla 24",
      },
    ],
  },

  {
    backgroundCard: ccxp_mexico01,
    logo: ccxp_mexico_logo,
    title: "CCXP México",
    subtitle: "",
    type: "festivais",
    position: 3,
    data: [
      { video: "lAsdeizO-M4" },
      {
        image: ccxp_mexico01,
        description: "CCXP México",
      },
      {
        image: ccxp_mexico02,
        description: "CCXP México",
      },
      {
        image: ccxp_mexico03,
        description: "CCXP México",
      },
      {
        image: ccxp_mexico04,
        description: "CCXP México",
      },
    ],
  },
  {
    backgroundCard: marykay01,
    logo: marykay_logo,
    title: "Seminário Viva Mary Kay 24",
    subtitle: "",
    type: "convenções",
    data: [
      { video: "PH5jaSzEwI4" },
      {
        image: marykay01,
        description: "Seminário Viva Mary Kay 24",
      },
      {
        image: marykay02,
        description: "Seminário Viva Mary Kay 24",
      },
      {
        image: marykay03,
        description: "Seminário Viva Mary Kay 24",
      },
    ],
  },
  {
    backgroundCard: gerdau01,
    logo: gerdau_logo,
    title: "Gerdau no The Town",
    subtitle: "",
    type: "festivais",
    position: 13,
    data: [
      { video: "z3jwdrfOrnQ" },
      {
        image: gerdau01,
        description: "Gerdau no The Town",
      },
      {
        image: gerdau02,
        description: "Gerdau no The Town",
      },
      {
        image: gerdau03,
        description: "Gerdau no The Town",
      },
      {
        image: gerdau04,
        description: "Gerdau no The Town",
      },
    ],
  },
  {
    backgroundCard: amazonprimeccxp01,
    logo: amazonprimeccxp_logo,
    title: "Amazon Prime na CCXP 23",
    subtitle: "",
    type: "festivais",
    position: 14,
    data: [
      { video: "HBBk5ceMrD8" },
      {
        image: amazonprimeccxp01,
        description: "Amazon Prime na CCXP 23",
      },
      {
        image: amazonprimeccxp02,
        description: "Amazon Prime na CCXP 23",
      },
      {
        image: amazonprimeccxp03,
        description: "Amazon Prime na CCXP 23",
      },
      {
        image: amazonprimeccxp04,
        description: "Amazon Prime na CCXP 23",
      },
    ],
  },
  {
    backgroundCard: trescoracoesccxp01,
    logo: trescoracoesccxp_logo,
    title: "3 Corações na CCXP 23",
    subtitle: "",
    type: "festivais",
    position: 15,
    data: [
      {
        image: trescoracoesccxp01,
        description: "3 Corações na CCXP 23",
      },
      {
        image: trescoracoesccxp02,
        description: "3 Corações na CCXP 23",
      },
      {
        image: trescoracoesccxp03,
        description: "3 Corações na CCXP 23",
      },
      {
        image: trescoracoesccxp04,
        description: "3 Corações na CCXP 23",
      },
      {
        image: trescoracoesccxp05,
        description: "3 Corações na CCXP 23",
      },
    ],
  },
  //   {
  //   backgroundCard: trescoracoesccxp01,
  //   logo: trescoracoesccxp_logo,
  //   title: "CCXP - 3 Corações",
  //   subtitle: "",
  //   type: "festivais",
  //   data: [
  //     {
  //       image: trescoracoesccxp01,
  //       description: "CCXP - 3 Corações",
  //     },
  //     {
  //       image: trescoracoesccxp02,
  //       description: "CCXP - 3 Corações",
  //     },
  //     {
  //       image: trescoracoesccxp03,
  //       description: "CCXP - 3 Corações",
  //     },
  //     {
  //       image: trescoracoesccxp04,
  //       description: "CCXP - 3 Corações",
  //     },
  //     {
  //       image: trescoracoesccxp05,
  //       description: "CCXP - 3 Corações",
  //     },
  //   ],
  // },
  {
    backgroundCard: netflixccxp2023_01,
    logo: netflixccxp2023_logo,
    title: "Netflix na CCXP 23",
    subtitle: "",
    type: "festivais",
    position: 16,
    data: [
      {
        image: netflixccxp2023_01,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixccxp2023_02,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixccxp2023_03,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixccxp2023_04,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixccxp2023_05,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixccxp2023_06,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixccxp2023_07,
        description: "Netflix na CCXP 23",
      },
      {
        image: netflixccxp2023_08,
        description: "Netflix na CCXP 23",
      },
    ],
  },
  {
    backgroundCard: paramountccxp2023_01,
    logo: paramountccxp2023_logo,
    title: "Paramount na CCXP 23",
    subtitle: "",
    type: "festivais",
    position: 17,
    data: [
      {
        image: paramountccxp2023_01,
        description: "Paramount na CCXP 23",
      },
      {
        image: paramountccxp2023_02,
        description: "Paramount na CCXP 23",
      },
      {
        image: paramountccxp2023_03,
        description: "Paramount na CCXP 23",
      },
      {
        image: paramountccxp2023_04,
        description: "Paramount na CCXP 23",
      },
      {
        image: paramountccxp2023_05,
        description: "Paramount na CCXP 23",
      },
      {
        image: paramountccxp2023_06,
        description: "Paramount na CCXP 23",
      },
      {
        image: paramountccxp2023_07,
        description: "Paramount na CCXP 23",
      },
      {
        image: paramountccxp2023_08,
        description: "Paramount na CCXP 23",
      },
    ],
  },
  {
    backgroundCard: xboxccxp2023_01,
    logo: xboxccxp2023_logo,
    title: "Xbox na CCXP 23",
    subtitle: "",
    type: "festivais",
    position: 18,
    data: [
      {
        image: xboxccxp2023_01,
        description: "Xbox na CCXP 23",
      },
      {
        image: xboxccxp2023_02,
        description: "Xbox na CCXP 23",
      },
      {
        image: xboxccxp2023_03,
        description: "Xbox na CCXP 23",
      },
      {
        image: xboxccxp2023_04,
        description: "Xbox na CCXP 23",
      },
      {
        image: xboxccxp2023_05,
        description: "Xbox na CCXP 23",
      },
      {
        image: xboxccxp2023_06,
        description: "Xbox na CCXP 23",
      },
      {
        image: xboxccxp2023_07,
        description: "Xbox na CCXP 23",
      },
    ],
  },
  {
    backgroundCard: globoccxp2023_01,
    logo: globoccxp2024_logo,
    title: "Globoplay na CCXP 23",
    subtitle: "",
    type: "festivais",
    position: 19,
    data: [
      {
        image: globoccxp2023_01,
        description: "Globoplay na CCXP 23",
      },
      {
        image: globoccxp2023_02,
        description: "Globoplay na CCXP 23",
      },
      {
        image: globoccxp2023_03,
        description: "Globoplay na CCXP 23",
      },
      {
        image: globoccxp2023_04,
        description: "Globoplay na CCXP 23",
      },
      {
        image: globoccxp2023_05,
        description: "Globoplay na CCXP 23",
      },
      {
        image: globoccxp2023_06,
        description: "Globoplay na CCXP 23",
      },
      {
        image: globoccxp2023_07,
        description: "Globoplay na CCXP 23",
      },
    ],
  },
  {
    backgroundCard: outbackccxp2023_01,
    logo: outbackccxp2023_logo,
    title: "Outback na CCXP 23",
    subtitle: "",
    type: "festivais",
    position: 20,
    data: [
      {
        image: outbackccxp2023_01,
        description: "Outback na CCXP 23",
      },
      {
        image: outbackccxp2023_02,
        description: "Outback na CCXP 23",
      },
      {
        image: outbackccxp2023_03,
        description: "Outback na CCXP 23",
      },
      {
        image: outbackccxp2023_04,
        description: "Outback na CCXP 23",
      },
      {
        image: outbackccxp2023_05,
        description: "Outback na CCXP 23",
      },
      {
        image: outbackccxp2023_06,
        description: "Outback na CCXP 23",
      },
    ],
  },
  {
    backgroundCard: bayer03,
    logo: bayer_logo,
    title: "Bayer Talks",
    subtitle: "",
    type: "convenções",
    data: [
      {
        image: bayer01,
        description: "Bayer Talks",
      },
      {
        image: bayer02,
        description: "Bayer Talks",
      },
      {
        image: bayer03,
        description: "Bayer Talks",
      },
      {
        image: bayer04,
        description: "Bayer Talks",
      },
    ],
  },
  {
    backgroundCard: bayeracao01,
    logo: bayeracao_logo,
    title: "Convenção Crop Science 23",
    subtitle: "",
    type: "convenções",
    data: [
      { video: "fAote6-OGKY" },
      {
        image: bayeracao01,
        description: "Convenção Crop Science 23",
      },
      {
        image: bayeracao02,
        description: "Convenção Crop Science 23",
      },
      {
        image: bayeracao03,
        description: "Convenção Crop Science 23",
      },
      {
        image: bayeracao04,
        description: "Convenção Crop Science 23",
      },
    ],
  },
  {
    backgroundCard: localizaconvencao01,
    logo: localizaconvencao_logo,
    title: "Localiza Convenção",
    subtitle: "",
    type: "convenções",
    data: [
      {
        image: localizaconvencao01,
        description: "Localiza Convenção",
      },
      {
        image: localizaconvencao02,
        description: "Localiza Convenção",
      },
      {
        image: localizaconvencao03,
        description: "Localiza Convenção",
      },
      {
        image: localizaconvencao04,
        description: "Localiza Convenção",
      },
    ],
  },
];

export default [
  data,
  data
    ?.filter((x) => x.type === "festivais")
    ?.sort((a, b) => (a.position || 0) - (b.position || 0)),
  data?.filter((x) => x.type === "corporativo & B2B"),
  data?.filter((x) => x.type === "lançamentos"),
  data?.filter((x) => x.type === "evento proprietário"),
  data?.filter((x) => x.type === "convenções"),
];
