import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.small};
    max-width: ${theme.grid.container};
    margin: 0 auto;
    width: 100%;
    max-height: calc(100vh - 84px);

    ${media.lessThan("medium")`
      padding: 0;
      max-height: 100vh;
    `}

    &::before {
      position: absolute;
      left: 0;
      content: "";
      border-top: 0.5px solid black;
      width: 100%;
      display: block;
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 32px;
    color: ${theme.colors.grayscale.light.title};
    position: relative;
    margin: 0 0 32px 0;
    font-weight: ${theme.font.bold};

    padding-top: 40px;

    ${media.lessThan("medium")`
      font-size: 40px;
      margin-bottom: ${theme.spacings.xsmall};
    `};
  `}
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;
  display: block;
  text-align: center;

  ${media.lessThan("medium")`
    margin-top: 0;
  `}
`;

export const Nav = styled.nav`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px;

    overflow-x: auto;
    overflow-y: hidden;
    @media (max-width: 1020px) {
      padding: 0;
      justify-content: flex-start;
      padding-left: 20px;
      min-height: ${theme.spacings.xxlarge};
    }
  `}
`;

export const NavUl = styled.div`
  display: flex;
  
  width: 100%;
  height: 40px;
  
  justify-content: space-between;

  ${media.lessThan("medium")`
    width: unset;
  `}
`;

export const NavItem = styled.li`
  ${({ theme }) => css`
    color: black;
    list-style: none;
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.black};
  `}
`;

const navLinkModifiers = {
  active: () => css`
    &::after {
      visibility: 100%;
  
    }
  `,
};

export const NavLink = styled.button`
  ${({ theme, active  }) => css`
    color: black;
    list-style: none;
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bold};
    position: relative;
    cursor: pointer;
    outline: none;
    line-height: 1.2;

    @media (max-width: 1020px) {
      text-align: center;
      /* margin-right: 20px; */
      font-size: 18px;
      max-width: 210px;

      &:nth-child(1) {
        width: 90px;
      }

      &:nth-child(2) {
        width: 160px;
      }

      &:nth-child(3) {
        width: 230px;
      }

      &:nth-child(4) {
        width: 210px;
      }

      &:nth-child(5) {
        width: 210px;
      }

      &:nth-child(6) {
        width: 200px;
      }
    }

    &::after {
      background-color: #2ee577;
      border-radius: 4px;
      content: '';
      position: absolute;
      width: 0;
      height: 4px;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
      transition: width 0.25s ease;
    }

    &:hover,
    &:focus{
      &::after {
        width: 100%;
      }
    }

    ${active && navLinkModifiers.active(theme)};
  `}
`;
